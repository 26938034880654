<template>
  <div>
    <a-modal
      v-model="isShowFirstStepModal"
      centered
      :width="800"
      :mask-closable="false"
      ok-text="下一步"
      :ok-button-props="{ props: { loading: firstStepSubmitting } }"
      title="变更运营商套餐"
      @ok="handleValidate"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :form="firstStepForm"
          @submit="handleValidate"
        >
          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-card-input
                :rows="15"
                ref="iccidInput"
                v-decorator="['iccids', {
                  initialValue: { data: this.iccids },
                  rules: [{ validator: checkIccids }],
                }]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>

    <a-modal
      v-model="isShowSubmitModal"
      centered
      :width="600"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="变更运营商套餐"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="simiot-compact-form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          :form="submitForm"
          @submit="handleSubmit"
        >
          <a-form-item label="已选择卡数">
            <a-input
              disabled
              v-model="this.validIccids.length"
            />
          </a-form-item>

          <a-form-item label="运营商套餐">
            <a-select
              v-decorator="['carrier_product_id', {
                rules: [
                  { required: true, message: '请选择运营商套餐' }
                ]
              }]"
              :loading="loadingCarrierProductOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in carrierProductOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { changeSimCardsCarrierProduct, validateChangeSimCardsCarrierProduct } from '@/api/batch_operation'
import CommonCardInput from '@/components/CardInput/Common'
import { findPurchasableCarrierAccountProductOptions } from '@/api/common_options'

export default {
  name: 'BatchOperationChangeSimCardsCarrierProduct',
  components: { CommonCardInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    iccids: {
      type: Array,
      default() {
        return []
      }
    },
    isInventory: {
      type: Boolean,
      required: true
    }
  },
  data() {
    const isShowFirstStepModal = this.visible
    return {
      loadingCarrierProductOptions: false,
      carrierAccountId: 0,
      carrierProductOptionsMap: {},
      carrierProductOptions: [],
      firstStepSubmitting: false,
      submitting: false,
      validIccids: [],
      isShowFirstStepModal: isShowFirstStepModal,
      isShowSubmitModal: false,
      firstStepForm: this.$form.createForm(this, { name: 'change_carrier_product_first_step' }),
      submitForm: this.$form.createForm(this, { name: 'change_carrier_product_submit' })
    }
  },
  watch: {
    // 步骤中任何一个弹窗打开，则 isShow 应为 true
    isShowFirstStepModal(val) {
      this.isShow = val || this.isShowSubmitModal
    },

    isShowSubmitModal(val) {
      this.isShow = val || this.isShowFirstStepModal
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
  },
  methods: {
    fetchCarrierProductOptions() {
      this.loadingCarrierProductOptions = true
      findPurchasableCarrierAccountProductOptions(this.carrierAccountId).then((res) => {
        if (res.code === 0) {
          this.carrierProductOptions = res.data
          this.loadingCarrierProductOptions = false
          res.data.forEach((element) => {
            this.carrierProductOptionsMap[element.id] = element
          })
        }
        this.loadingCarrierProductOptions = false
      })
    },

    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入卡号')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleValidate(e) {
      e.preventDefault()
      if (this.firstStepSubmitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.firstStepForm.validateFields((err, values) => {
        if (!err) {
          this.firstStepSubmitting = true
          validateChangeSimCardsCarrierProduct({
            iccids: values.iccids.data,
            is_inventory: this.isInventory
          }).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                // 关闭模态框
                this.isShowSubmitModal = true
                this.validIccids = values.iccids.data
                this.carrierAccountId = res.data.carrier_account_id
                this.isShowFirstStepModal = false

                this.fetchCarrierProductOptions()
              } else {
                if (res.data.carrier_account_count > 1) {
                  this.$message.error('对应运营商不唯一')
                } else {
                  this.firstStepForm.setFieldsValue({
                    iccids: { inputValue: res.data.result }
                  })
                }

                this.$nextTick(() => {
                  this.$refs.iccidInput.handleCards()
                })
              }
            }
            this.firstStepSubmitting = false
          })
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          changeSimCardsCarrierProduct({
            ...values,
            iccids: this.validIccids,
            is_inventory: this.isInventory
          }).then((res) => {
            if (res.code === 0) {
              this.isShowSubmitModal = false
              // 告知父组件已完成
              this.$emit('completed')
              this.$success({
                title: '提交成功',
                content: (
                  <div>
                    <p>操作成功，具体操作结果请至 【系统查询】-【批量操作查询】 查看</p>
                  </div>
                )
              })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
